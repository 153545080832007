import { graphql } from "gatsby";
import * as React from "react";
import get from "lodash/get";

import {
  ButtonsSwitches,
  BundleItem,
  PersonasCarousel,
} from "../sections/kiosk";

import {
  KioskHeader,
} from "../components/";

import {
  Hero,
} from "../sections/standard";

import {
  PersonaHero,
  Content,
} from "../sections/persona";

const kioskSections = {
  ButtonsSwitches: ButtonsSwitches,
  BundleItem: BundleItem,
}

const nonKioskSections = {
  Hero: Hero,
  PersonasCarousel: PersonasCarousel,
}

const personaSections = {
  Hero: PersonaHero,
  Content: Content,
}

class KioskPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulTemplateKiosk");
    return (
      <div>
        <KioskHeader />
        {page_data.sections.map((section, index) => {
          if(section.internal.type.includes('Kiosk')){
            const Section = kioskSections[section.internal.type.replace("ContentfulKioskSection", "")];
            return (
            <Section
              data={section}
              key={index}
            />
          );
          }else if(section.internal.type.includes('ContentfulPersona')){
            const Section = personaSections[section.internal.type.replace("ContentfulPersonaSection", "")];
            return (
            <Section
              data={section}
              key={index}
            />
          );
          }
          else{
            const Section = nonKioskSections[section.internal.type.replace("ContentfulSection", "")];
            return (
            <Section
              data={section}
              key={index}
            />
          );
          }
        })}
      </div>
    )
  }
}

export default KioskPageTemplate;
// this $path is just a prop or context from the parent that was handed down
export const pageQuery = graphql`
  query KioskPageQuery($path: String!){
    contentfulTemplateKiosk(path: { eq: $path}) {
      path
      title
      description {
        description
      }
      sections {
        ... on ContentfulKioskSectionButtonsSwitches {
          internal {
            type
          }
          id
          header
          buttonLabels
          layoutType
          urls
          images {
            title
            url
            gatsbyImageData
          }
          backToHomeButton
        }
        ... on ContentfulPersonaSectionHero {
            internal {
              type
            }
            id
            heading {
              heading
            }
            subheading
            description {
              description
            }
            image {
              title
              file {
                url
              }
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            contentsTitle
            contentsTable {
              title
            }
            socialLinks {
              type
              url
            }
            ctaLabel
            ctaLink
            productImage{
              title
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            recProductUrl
          }
       ... on ContentfulKioskSectionBundleItem {
            internal {
              type
            }
            id
            bundles {
              header
              bundleTitle
              body {
                raw
              }
              products {
                subTitle
                title
                description {
                  description
                }
                badge
                image {
                  title
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    placeholder: BLURRED
                  )
                }
                price
                ctaLabel
                type
                ctaLink
              }
            }
          }
        ... on ContentfulSectionHero {
          internal {
            type
          }
          type
          id
          backgroundColor
          viewFullMenuButton
          heading {
            childMarkdownRemark {
              html
            }
          }
          bottomHeading
        }
        ... on ContentfulSectionPersonasCarousel {
          internal {
            type
          }
          id
          buttonLabel
          buttonUrl
          subHeader {
            subHeader
          }
          header
          personas {
            title
            subheading {
              subheading
            }
            description {
              description
            }
            image {
              url
              gatsbyImageData
              title
            }
            ctaLink
            ctaLabel
            icon {
              gatsbyImageData
              url
              title
            }
          }
        }
        ... on ContentfulPersonaSectionContent {
          internal {
            type
          }
          id
          body {
            raw
          }
        }
      }
    }
  }
`