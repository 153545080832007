// Core
import PropTypes from "prop-types";
import React from "react";

import {
  RichText,
} from "../../../components/";

class PersonaContent extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div className="px-6 py-12">
        <div className="cContainer">
          <div className="rte">
            <RichText json={JSON.parse(data.body.raw)} references={data.body.references} />
          </div>
        </div>
      </div>
    );
  }
}

PersonaContent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PersonaContent;
