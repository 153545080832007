import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'gatsby';

class ButtonsSwitches extends React.Component {
  render(){
    const data = this.props.data;
    const {backToHomeButton, buttonLabels, header, images, layoutType, urls} = data;
    
    return layoutType[0] === "Buttons" ?
      (<div className="cContainer bg-anja-light-purple-overlay-down-90 px-6 pb-12">
        <div className='flex flex-col w-full text-center justify-center gap-6'>
          {header && <h3>{header}</h3>}
          <div className="flex flex-row items-center justify-center ml-auto mr-auto flex-wrap gap-4">
            {buttonLabels.map((button, index)=>{
              const buttons_url = urls[index];
              // const label_length = buttonLabels.length;
              return (
                <Link to={buttons_url} className={["bg-white shadow-2xl w-[200px] h-[200px] flex justify-center items-center flex-col ",
                "rounded-tl-lg",
                "rounded-tr-lg",
                "rounded-bl-lg",
                "rounded-br-lg",
                ].join(" ")}>
                  <img
                    alt={images[index].title}
                    src={images[index].url}
                    className='w-full max-w-[125px] h-full max-h-[125px] object-contain'
                  />
                  <p className="mt-2 font-lato font-bold text-[28px] text-purple">{button}</p>
                </Link>
              );
            })}
          </div>
          {backToHomeButton && <div className="mt-12 w-full flex justify-center items-center">
            <Link to='/kiosk/persona' className="button button--primary border-2">Back to Home</Link>
          </div>}
        </div>
      </div>)
      :
      (
      <div className="pt-20 cContainer bg-anja-light-purple px-6 pb-12">
        <div className='flex flex-col w-full text-center justify-center gap-6'>
          {header && <h3>{header}</h3>}
          <div className="flex flex-col items-center gap-7">
            {buttonLabels.map((button, index)=>{
              const split_texts = button.split('/');
              return (
                <div className="w-full flex flex-row items-center justify-center">
                  <Link to={urls[index*2]} className="button button--primary mx-4 border-2 border-purple">{split_texts[0]}</Link>
                  <Link to={urls[index*2+1]} className="button button--primary mx-4 border-2 border-purple">{split_texts[1]}</Link>
                </div>
                
              );
            })}
          </div>
          {backToHomeButton && <div className="w-full flex justify-center items-center">
            <Link to='/kiosk/persona' className="button button--primary border-2 border-purple">Back to Home</Link>
          </div>}
        </div>
      </div>
      );
  }
}

ButtonsSwitches.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ButtonsSwitches