// Core
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

class PersonaHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("keydown", this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  handleCloseModal(event) {
    let current_target = event.target;
    let close_modal = `${current_target.dataset.modal}` !== "true";

    while (
      current_target.parentElement &&
      close_modal === true
    ) {
      current_target = current_target.parentElement;

      if (current_target.dataset.modal) {
        close_modal = false;
        break;
      }
    }

    if (close_modal) {
      this.setState({ modal: false });
    }
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.setState({ modal: false });
    }
  }

  handleShare() {
    if (window?.navigator?.share) {
      window.navigator.share({
        text: this.props.data.description,
        title: this.props.data.title,
        url: window.location.href,
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      this.setState({ modal: true });
    }
  }

  render() {
    const data = this.props.data;

    return (
      <div>
        <div className="mb-0 bg-light-purple">
          <div className="relative flex flex-col">
            <div className="cContainer">
             <div className="bg-light-purple">
                <div className="px-6 pb-8 md:pb-28 relative">
                  <div className="cContainer flex items-center flex-col justify-center">
                      <div className="text-center w-full pt-8 lg:pt-0">
                        <h1 className="text-h3 md:text-[60px] lg:text-[70px] tracking-[1px] leading-[123%] md:leading-[119%] font-lato font-black text-white mb-16">{data.heading.heading}</h1>
                        
                        {data.subheading && <h1 className="text-h5 md:text-[30px] lg:text-[32px] leading-[30px] font-lato font-extrabold italic text-white">{data.subheading}</h1>}
                        {data.description && <h6 className="mt-8 text-p font-karla md:text-h5-m leading-[26px] font-normal text-white">{data.description.description}</h6>}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 bg-anja-light-purple-overlay-down">
          <div className="cContainer">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-x-8">
              <div className="">
                <div className="">
                  <h2 className="text-white italic text-center lg:text-left">Discretion</h2>
                  <h2 className="text-white text-center lg:text-left">Table of Contents:</h2>
                  <ul className="mt-4">
                    {data.contentsTable.map((item, index) => {
                      return (
                        <li className="flex flex-col lg:flex-row items-center mt-10" key={index}>
                          <span className="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-purple text-white font-lato text-h3">{index + 1}</span>
                          <h3 className="text-purple text-center lg:text-left lg:ml-6">{item.title}</h3>
                        </li>
                      );
                    })}
                  </ul>
                  
                  <div className="my-8 flex items-center flex-col justify-center">
                    <GatsbyImage title={data.productImage.title} image={getImage(data.productImage.gatsbyImageData)} />
                    <Link
                      className="button button--primary !py-4 !max-w-[350px]"
                      to={data.recProductUrl}
                    >
                      Recommended Products
                    </Link>
                  </div>
                  
                </div>
                
              </div>
              <div className="relative flex items-center justify-center">
                <GatsbyImage
                  alt={data.image.title}
                  image={data.image.gatsbyImageData}
                />
                <div className="absolute left-0 w-full bottom-[5%] flex items-center justify-evenly">
                  <Link
                    className="button button--primary button--small !py-4 w-[220px]"
                    to="https://formfields.typeform.com/to/xwAUtYkP?utm_source=xxxxx"
                  >
                    TAKE QUIZ
                  </Link>
                  <button
                    className="button button--primary button--small !py-4 w-[220px]"
                    onClick={this.handleShare}
                  >
                    {data.ctaLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        {this.state.modal && (
          <div
            className={[
              "bg-black/50",
              "bottom-0",
              "fixed",
              "flex",
              "items-center",
              "justify-center",
              "left-0",
              "right-0",
              "top-0",
              "z-50",
            ].join(" ")}
            onClick={this.handleCloseModal}
          >
            <div
              className={[
                "bg-white",
                "mx-4",
                "p-6",
                "rounded-md",
                "text-center",
              ].join(" ")}
              data-modal
            >
              <h3>URL copied to clipboard! Share on:</h3>

              <ul className="flex items-center -mx-4 py-4 px-6 bg-white rounded-md justify-center mt-8">
                {data.socialLinks.map((link, index) => {
                  return (
                    <li className={[
                        "px-4",
                        index > 0 ? 'border-l border-light-grey' : ''
                      ].join(' ')}
                      key={index}
                    >
                      <a href={link.url} className="w-7 h-7 flex items-center justify-center">
                        {link.type == 'instagram' &&
                          <StaticImage
                            src="../../../images/icon-instagram-purple.png"
                            placeholder="blurred"
                            alt="Instagram"
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                          />
                        }
                        {link.type == 'facebook' &&
                          <StaticImage
                            src="../../../images/icon-facebook-purple.png"
                            placeholder="blurred"
                            alt="Facebook"
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                          />
                        }
                        {link.type == 'twitter' &&
                          <StaticImage
                            src="../../../images/icon-twitter-purple.png"
                            placeholder="blurred"
                            alt="Facebook"
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                          />
                        }
                        {link.type == 'snapchat' &&
                          <StaticImage
                            src="../../../images/icon-snapchat-purple.png"
                            placeholder="blurred"
                            alt="Snapchat"
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                          />
                        }
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PersonaHero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PersonaHero;
