// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from 'styled-components';

// Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PersonasCarousel = (pageContext) => {

    const data = pageContext.data;
    const [selected, setSelected] = useState("0");

    const Wrapper = styled.div`

      .slick-track {
        display: flex;
      }

      .slick-dots li {
        width: 90px;
        background-color: #61149E;
        opacity: 0.5;
        height: 10px;
        border-radius: 9999px;
      }

      .slick-active {
        opacity: 1 !important;
      }
    `

    const carouselSettings = {
      arrows: false,
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '0px',
      variableWidth: false,
      beforeChange: (current, next) => setSelected(next),
      customPaging: i => (
        <div className="h-[10px] w-[90px]"></div>
      )
    }

    useEffect(() => {
      const slick_dots = document.querySelector(".slick-dots");
      slick_dots.childNodes[selected].children[0].click();
    }, [selected]);

    return (
      <Wrapper>
      <div id="persona-carousel" className="pt-[35rem] pb-16 relative" data-personas-carousel>
        <div className="absolute top-12 left-0 w-full px-6">
          <div className="w-full mb-8">
            <h2 className="text-center text-h1 font-semibold text-purple">What's Your Persona?</h2>
            <h6 className="text-center text-p-m font-normal text-purple">Select One</h6>
          </div>
          <div className="grid grid-cols-4 gap-4 w-full mx-auto px-5 place-items-center max-w-[800px]">
            {data.personas.map((persona, index) => (
              <div className={["w-200 h-200 rounded-full overflow-hidden",
              index === selected ? 'border-8 border-purple' : '',]
                    .join(" ")} key={index}>
                <button value={index} onClick={(e)=>{
                  e.preventDefault();
                  setSelected(e.currentTarget.value);
                }}>
                  <GatsbyImage
                    image={persona.image.gatsbyImageData}
                    alt={persona.image.title}
                    className="w-full h-full rounded-lg"
                    imgClassName="rounded-lg"
                  />
                </button>
              </div>
            ))}
          </div>
          
        </div>
        <Slider {...carouselSettings}>
          {data.personas.map((persona, index) => {
            return (
              <div className="text-white px-28 py-20" key={index}>
                <div className="relative gap-4 flex flex-col justify-center mx-auto w-[70vw] sm:w-[480px] min-h-[320px] md:min-h-[480px] bg-[#A983C8] text-white px-4 md:px-8 py-4 shadow-persona ml-[-40px] sm:ml-0 pr-[75px] sm:pr-4 rounded-[92px] ">
                  <div>
                    <h3 className="text-p-xxl md:text-h3 text-white">{persona.title}</h3>
                    <div className="mt-4 italic text-p-md md:text-p-xxl max-w-[300px] font-bold">{persona.subheading.subheading}</div>
                    <p className="mt-4 mb-6 italic text-p-s max-w-[300px] font-normal">"{persona.description.description}"</p>
                    <Link to={persona.ctaLink} className="button button--persona">{persona.ctaLabel}</Link>
                  </div>
                    
                  <div className="absolute top-0 right-0 translate-x-1/2 w-[504px] h-[405px] p-4 md:p-10">
                    <div className="flex items-center absolute w-full h-full left-1/2 top-1/2 -translate-x-1/4 -translate-y-[40%] rounded-[92px]">
                      <img
                        alt={persona.image.title}
                        src={persona.image.url}
                        className="w-full h-full rounded-3xl object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="flex flex-col gap-5 mt-20 text-center mx-auto max-w-4xl">
          <h3 className="tracking-[1px] font-lato text-purple">{data.header}</h3>
          <p>{data.subHeader.subHeader}</p>
          <Link to="https://formfields.typeform.com/to/xwAUtYkP?utm_source=xxxxx" className="button button--kiosk mx-auto">Take Quiz</Link>
        </div>
      </div>
      </Wrapper>
    );

}

PersonasCarousel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PersonasCarousel;
