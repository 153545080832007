import React, { useState } from 'react';
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

//images
import desktop_temp from "../../../images/bundle--desktop.png";

const BundleItem = (pageContext) => {

  const _handleClick = (e) => {
    e.preventDefault();
    setItemIndex(e.target.value)
  }

  const [itemIndex, setItemIndex] = useState("0");

  const data = pageContext.data;
  const { bundles } = data;
  return (
    <div className='cContainer pb-8 bg-anja-light-purple-overlay-down'>
      <div className='mx-auto rounded-full py-1 bg-purple w-full max-w-[900px] flex flex-row items-center justify-evenly mb-16 text-center'>
        {bundles.map((item, index)=>{
          return itemIndex === index.toString() ?
              (<button className="font-lato mx-4 px-6 py-4 rounded-full border-2 border-white text-purple bg-white" value={index} onClick={_handleClick}>{item.bundleTitle}</button>)
              :
              (<button className="font-lato mx-4 px-6 py-4 rounded-full border-2 hover:border-white hover:text-purple hover:bg-white text-white border-purple" value={index} onClick={_handleClick}>{item.bundleTitle}</button>)
        })}
      </div>

      {bundles.map((item, index)=>{
        return(
          <div key={index}>
          {itemIndex === index.toString() &&
            <div className="cContainer relative">
          <img src={desktop_temp} alt="desktop_template" className='w-full h-full'/>
          <div className="absolute top-[0%] left-[5%] w-[48%]">
            <h4 className='font-lato text-white font-black leading-[41px] text-[48px]'>{item.bundleTitle}</h4>
            <p className='mt-6 text-p font-karla leading-[26px] font-normal text-white'>{item.body.body}</p>
          </div>
          <div className="absolute top-0 left-0 w-full h-full">
            {item.products &&
            <>
              <div className='absolute top-[52%] left-[25%]'>
                <GatsbyImage alt={item.products[0].image.title} image={getImage(item.products[0].image.gatsbyImageData)}/>
              </div>
              <div className='absolute top-[60%] left-[5%]'>
                <div className='flex flex-col items-start'>
                  <div className='bg-white px-1'>
                    <p className='font-lato text-black font-normal text-[16px]'>{item.products[0].type[0]}</p>
                  </div>
                  <h6 className='font-bold font-lato text-white text-[30px]'>{item.products[0].title}</h6>
                  <div className='flex flex-row items-center'>
                    <span className='font-lato text-[24px] font-bold text-white mr-2'>{item.products[0].price}</span>
                    <a className="button button--primary" href={item.products[0].ctaLink}>{item.products[0].ctaLabel}</a>
                  </div>
                </div>
              </div>
            </>
            }
            {item.products &&
            <>
              <div className='absolute top-[61%] left-[42%]'>
                <GatsbyImage alt={item.products[1].image.title} image={getImage(item.products[1].image.gatsbyImageData)}/>
              </div>
              <div className='absolute top-[74%] left-[60%]'>
                <div className='flex flex-col items-start'>
                  <div className='bg-white px-1'>
                    <p className='font-lato text-black font-normal text-[16px]'>{item.products[1].type[0]}</p>
                  </div>
                  <h6 className='font-bold font-lato text-white text-[30px]'>{item.products[1].title}</h6>
                  <div className='flex flex-row items-center'>
                    <span className='font-lato text-[24px] font-bold text-white mr-2'>{item.products[1].price}</span>
                    <a className="button button--primary" href={item.products[1].ctaLink}>{item.products[1].ctaLabel}</a>
                  </div>
                </div>
              </div>
            </>
            }
            {item.products &&
            <>
              <div className='absolute top-[72%] left-[12%]'>
                <GatsbyImage alt={item.products[2].image.title} image={getImage(item.products[2].image.gatsbyImageData)}/>
              </div>
              <div className='absolute top-[100%] left-[5%]'>
                <div className='flex flex-col items-start'>
                  <div className='bg-black px-1'>
                    <p className='font-lato text-white font-normal text-[16px]'>{item.products[2].type[0]}</p>
                  </div>
                  <h6 className='font-bold font-lato text-purple text-[30px]'>{item.products[2].title}</h6>
                  <div className='flex flex-row items-center'>
                    <span className='font-lato text-[24px] font-bold text-purple mr-2'>{item.products[2].price}</span>
                    <a className="button button--primary" href={item.products[2].ctaLink}>{item.products[2].ctaLabel}</a>
                  </div>
                </div>
              </div>
            </>
            }
            {item.products &&
            <>
              <div className='absolute top-[70%] left-[82%]'>
                <GatsbyImage alt={item.products[3].image.title} image={getImage(item.products[3].image.gatsbyImageData)}/>
              </div>
              <div className='absolute top-[100%] left-[75%]'>
                <div className='flex flex-col items-start'>
                  <div className='bg-black px-1'>
                    <p className='font-lato text-white font-normal text-[16px]'>{item.products[3].type[0]}</p>
                  </div>
                  <h6 className='font-bold font-lato text-purple text-[30px]'>{item.products[3].title}</h6>
                  <div className='flex flex-row items-center'>
                    <span className='font-lato text-[24px] font-bold text-purple mr-2'>{item.products[3].price}</span>
                    <a className="button button--primary" href={item.products[3].ctaLink}>{item.products[3].ctaLabel}</a>
                  </div>
                </div>
              </div>
            </>
            }
          </div>
        </div>}
        </div>
        )
        
      })}


      {/* <div className="cContainer relative">
        <img src={desktop_temp} alt="desktop_template" className='w-full h-full'/>
        <div className="absolute top-[0%] left-[5%] w-[48%]">
          <h4 className='font-lato text-white font-black leading-[41px] text-[48px]'>{first_item.header}</h4>
          <p className='mt-6 text-p font-karla leading-[26px] font-normal text-white'>{first_item.body.body}</p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full">
          {first_item.products &&
          <>
            <div className='absolute top-[52%] left-[25%]'>
              <GatsbyImage alt={first_item.products[0].image.title} image={getImage(first_item.products[0].image.gatsbyImageData)}/>
            </div>
            <div className='absolute top-[60%] left-[5%]'>
              <div className='flex flex-col items-start'>
                <div className='bg-white px-1'>
                  <p className='font-lato text-black font-normal text-[16px]'>{first_item.products[0].type[0]}</p>
                </div>
                <h6 className='font-bold font-lato text-white text-[30px]'>{first_item.products[0].title}</h6>
                <div className='flex flex-row items-center'>
                  <span className='font-lato text-[24px] font-bold text-white mr-2'>{first_item.products[0].price}</span>
                  <a className="button button--primary" href={first_item.products[0].ctaLink}>{first_item.products[0].ctaLabel}</a>
                </div>
              </div>
            </div>
          </>
          }
          {first_item.products &&
          <>
            <div className='absolute top-[61%] left-[42%]'>
              <GatsbyImage alt={first_item.products[1].image.title} image={getImage(first_item.products[1].image.gatsbyImageData)}/>
            </div>
            <div className='absolute top-[74%] left-[60%]'>
              <div className='flex flex-col items-start'>
                <div className='bg-white px-1'>
                  <p className='font-lato text-black font-normal text-[16px]'>{first_item.products[1].type[0]}</p>
                </div>
                <h6 className='font-bold font-lato text-white text-[30px]'>{first_item.products[1].title}</h6>
                <div className='flex flex-row items-center'>
                  <span className='font-lato text-[24px] font-bold text-white mr-2'>{first_item.products[1].price}</span>
                  <a className="button button--primary" href={first_item.products[1].ctaLink}>{first_item.products[1].ctaLabel}</a>
                </div>
              </div>
            </div>
          </>
          }
          {first_item.products &&
          <>
            <div className='absolute top-[72%] left-[12%]'>
              <GatsbyImage alt={first_item.products[2].image.title} image={getImage(first_item.products[2].image.gatsbyImageData)}/>
            </div>
            <div className='absolute top-[100%] left-[5%]'>
              <div className='flex flex-col items-start'>
                <div className='bg-black px-1'>
                  <p className='font-lato text-white font-normal text-[16px]'>{first_item.products[2].type[0]}</p>
                </div>
                <h6 className='font-bold font-lato text-purple text-[30px]'>{first_item.products[2].title}</h6>
                <div className='flex flex-row items-center'>
                  <span className='font-lato text-[24px] font-bold text-purple mr-2'>{first_item.products[2].price}</span>
                  <a className="button button--primary" href={first_item.products[2].ctaLink}>{first_item.products[2].ctaLabel}</a>
                </div>
              </div>
            </div>
          </>
          }
          {first_item.products &&
          <>
            <div className='absolute top-[70%] left-[82%]'>
              <GatsbyImage alt={first_item.products[3].image.title} image={getImage(first_item.products[3].image.gatsbyImageData)}/>
            </div>
            <div className='absolute top-[100%] left-[75%]'>
              <div className='flex flex-col items-start'>
                <div className='bg-black px-1'>
                  <p className='font-lato text-white font-normal text-[16px]'>{first_item.products[3].type[0]}</p>
                </div>
                <h6 className='font-bold font-lato text-purple text-[30px]'>{first_item.products[3].title}</h6>
                <div className='flex flex-row items-center'>
                  <span className='font-lato text-[24px] font-bold text-purple mr-2'>{first_item.products[3].price}</span>
                  <a className="button button--primary" href={first_item.products[3].ctaLink}>{first_item.products[3].ctaLabel}</a>
                </div>
              </div>
            </div>
          </>
          }
        </div>
      </div>
       */}
      <div className='mt-[12rem] text-center'>
        {/* <a href="/shop" className='uppercase button button--primary button--large border-2 border-purple !max-w-[620px]'>Back To Home</a>   */}
        <Link to='/kiosk/persona' className="button button--primary border-2 border-purple">Back to Home</Link>
      </div> 
    </div>
  )
  
}

BundleItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BundleItem;