import React from 'react';
import PropTypes from "prop-types";

class Hero extends React.Component {
  render(){
    const data = this.props.data;

    return(
      <div className="cContainer bg-anja-light-purple">
        <div className="px-6 pt-16 pb-12 flex flex-col w-full text-center items-center justify-center">
          <h1 className="text-white">{data.header}</h1>
          <h4 className="text-white italics font-bold">{data.subHeader.subHeader}</h4>
          {data.viewFullMenuButton === true &&
            <a href="https://menus.dispenseapp.com/75b89af98283d723/menu?kiosk=true" className='mt-4 button button--primary mx-4 border-2 border-purple'>View Full Menu</a>
          }
        </div>

        
      </div>
      
    )
  }
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero